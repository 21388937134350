import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`We’ve talked about `}<strong parentName="p">{`why`}</strong>{` and `}<strong parentName="p">{`how`}</strong>{` to use TypeScript in React apps.`}</p>
    <p><strong parentName="p">{`Slides`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/p90ry56yl0"
      }}>{`https://codesandbox.io/s/p90ry56yl0`}</a>{` (used Current Module View)`}</p>
    <hr></hr>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://haspar.us/you-deserve-more-than-proptypes"
        }}>{`https://haspar.us/you-deserve-more-than-proptypes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/typescript-cheatsheets/react-typescript-cheatsheet"
        }}>{`https://github.com/typescript-cheatsheets/react-typescript-cheatsheet`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/piotrwitek/react-redux-typescript-guide"
        }}>{`https://github.com/piotrwitek/react-redux-typescript-guide`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      